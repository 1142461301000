import React, { useMemo, useState } from 'react'
import ThemeProvider from 'ui/elements/ThemeProvider'

import Header from './Header'
import Footer from './Footer'
import { LanguageSwitcherContextProvider } from 'contexts/LanguageSwitcherContext'
import { IconsContextProvider } from 'contexts/IconsContext'
import { graphql, PageProps } from 'gatsby'
import InfoBar from './InfoBar/InfoBar'
import HeaderCTA from 'components/Layout/HeaderCTA/HeaderCTA'

export const query = graphql`
  fragment PageLayoutConfig on Query {
    ...HeaderCTAConfig
  }
`

const PageLayout: React.FC<PageProps<any, any>> = ({
  children,
  data,
  pageContext,
}) => {
  const hasNavigation = !pageContext.landingPage
  const [menuOpen, setMenuOpen] = useState<boolean>(false)
  const showCta = useMemo(() => {
    return (
      !data?.sanityLandingPage?.hide_global_ctas &&
      !data?.sanityPage?.hide_global_ctas
    )
  }, [data])

  return (
    <ThemeProvider>
      <LanguageSwitcherContextProvider
        translations={pageContext.translations}
        languages={pageContext.i18n.languages}
        originalPath={pageContext.i18n.originalPath}
        defaultLanguage={pageContext.i18n.defaultLanguage}
      >
        <IconsContextProvider>
          {!data?.sanityLandingPage && showCta && (
            <InfoBar
              config={data?.infoBarConfig ?? undefined}
              hideMobile={menuOpen}
            />
          )}
          <Header onMenuOpen={setMenuOpen} hasNavigation={hasNavigation} />
          {showCta && (
            <HeaderCTA config={data?.headerCTASiteConfig ?? undefined} />
          )}
          {children}
          <Footer />
        </IconsContextProvider>
      </LanguageSwitcherContextProvider>
    </ThemeProvider>
  )
}

export default PageLayout
