import React, { createContext, useContext, useMemo } from 'react'

export const useGetLinkTranslations = (translations: Record<string, string> | undefined, languages: string[], originalPath: string, defaultLanguage: string) => {
  const trans = useMemo(() => {
    if (translations) {
      return translations
    }

    const regexp = /\/+/g;
    return Object.fromEntries(languages.map(l => {
      const path = `/${l === defaultLanguage ? "" : l}/${originalPath}`.replaceAll(regexp, '/')
      return [l, path]
    }))
  }, [originalPath])

  return {
    translations: trans,
  }
}

const useCreateContext = useGetLinkTranslations

export const LanguageSwitcherContext = createContext<ReturnType<typeof useCreateContext> | null>(null)

export const useLanguageSwitcherContext = () => {
    const ctx = useContext(LanguageSwitcherContext)

    if (ctx === null) {
        throw new Error("No LanguageSwitcherContextProvider available.")
    }

    return ctx
}

export const LanguageSwitcherContextProvider: React.FC<React.PropsWithChildren<{
  translations: Record<string, string>
  languages: string[]
  originalPath: string
  defaultLanguage: string
}>> = ({ children, translations, languages, originalPath, defaultLanguage }) => {
    const ctx = useCreateContext(translations, languages, originalPath, defaultLanguage)

    return <LanguageSwitcherContext.Provider value={ctx}>
        {children}
    </LanguageSwitcherContext.Provider>
}
